<script setup lang="ts">
// 1. Open side panel
// 	1.1   EvaluationPending
// 	1.2   Evaluating
// 	1.3   NotEligible
// 	1.4   NotEligibleBasic
// 2. Does nothing
// 	2.1   New
// 	2.2   NotInvited
// 3. Go to campaign
// 	3.1   EligibleBasic
// 	3.2   Eligible

import type { SuggestedCampaignDto } from "~~/_api_generated_";
import { useRouter } from "vue-router";

interface Props {
  campaign: SuggestedCampaignDto;
  inModal?: boolean;
  landingPageMode?: boolean;
  customTotalBudgetText?: string;
  isFirst?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  inModal: false,
  landingPageMode: false,
  isFirst: false,
  customTotalBudgetText: "Total Budget",
});

const emit = defineEmits(["openSidebar"]);
const router = useRouter();

const inModal = computed(() => {
  return props.inModal;
});

const computedClasses = computed(() => {
  const isClickable =
    ["New", "NotInvited"].includes(props.campaign.eligibility.state) === false;

  const classes = [];

  if (isClickable) {
    classes.push("cursor-pointer");
  }

  if (props.isFirst) {
    classes.push(
      "border-[#CFCBFF] bg-[#CFCBFF] outline-none border-none",
      isClickable
        ? "bg-gradient-to-b hover:from-white hover:to-white hover:border-white"
        : "",
    );

    return classes;
  }

  if (inModal.value) {
    classes.push(
      "bg-gradient-to-b from-gray-100 to-gray-200 border border-gray-300 campaign-row",
      isClickable ? "hover:from-white hover:to-white hover:border-white" : "",
    );
  } else {
    classes.push(
      "border border-white bg-gradient-to-b from-white/50 to-transparent",
      isClickable ? "hover:bg-white hover:shadow-blur" : "",
    );
  }

  return classes;
});

function resolveClick() {
  if (
    props.campaign.eligibility.state === "Eligible" ||
    props.campaign.eligibility.state === "EligibleBasic"
  ) {
    router.push(`/campaign/${props.campaign.id}`);
  }

  if (props.campaign.eligibility.state === "NotEligibleBasic") {
    globalEmit("campaignForSidebarClicked", {
      campaignToOpen: props.campaign,
      seeCriteria: true,
    });
  }

  if (
    ["Evaluating", "NotEligible", "EvaluationPending"].includes(
      props.campaign.eligibility.state,
    )
  ) {
    globalEmit("campaignForSidebarClicked", {
      campaignToOpen: props.campaign,
      seeCriteria: false,
    });
  }

  if (
    props.campaign.eligibility.state === "NotEligible" ||
    props.campaign.eligibility.state === "NotEligibleBasic"
  ) {
    trackEvent("not_eligible_campaign_clicked", {
      campaignId: props.campaign.id,
    });
  }
}
</script>

<template>
  <NuxtLink
    v-if="
      props.campaign.eligibility.state === 'Eligible' ||
      props.campaign.eligibility.state === 'EligibleBasic'
    "
    :to="`/campaign/${props.campaign.id}`"
  >
    <CampaignRowContent :class="computedClasses" v-bind="props" />
  </NuxtLink>

  <div v-else @click="resolveClick">
    <CampaignRowContent :class="computedClasses" v-bind="props" />
  </div>
</template>
